@charset "UTF-8";
/* CSS Document */
/*共通　青枠を消す*/
*:focus {
    outline: none !important;
}
a {
    -webkit-tap-highlight-color:rgba(0,0,0,0);
    cursor:pointer;
}
a:hover {
    text-decoration: none;
}
/*タイポグラフィー*/
@media only screen and (max-width: 767px) {
    h2 {
	    font-size:1.6rem;
    }
}
/*ヘッダー*/
/* 固定ヘッダの高さ分 */
/* body#comprehensive_top {
    padding-top: 76px;
} */
nav.bg-light {
    background-color:#ffffff !important;
    border-top: solid 10px #2b3b56;
    box-shadow: 0 5px 5px 0px rgba(0,0,0,0.1);
}
.row.user-info {
    text-align: right;
    margin-top: -0.5rem;
    background-color: #eeeeee;
}
.row.user-info ul {
    list-style: none;
    margin-bottom: 0;
}
.brand-row {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
}
/* .icon-area {
    margin-top:6px;
} */
nav.navbar {
    display:block !important;
    padding: 0.5rem 1rem 0 1rem;
}
.mr-auto {
    margin:0 auto;
}
.navbar-expand-lg .navbar-collapse.security,
.navbar-expand-lg .navbar-collapse.client {
    margin:2px -16px -8px;
}
/* @media only screen and (min-width: 480px) {
    #comprehensive_top > nav > div > div.icon-area > p > a {
	    padding: 0.5rem;
	    position: relative;
	    top: 1px;
    }
} */
@media only screen and (max-width: 479px) {
    .brand-row {
	    padding:0;
    }
    #comprehensive_top > nav > div > div.logo-area > a > img {
	    position: relative;
	    top: -5px;
	    width:150px;
    }
    /* body#comprehensive_top {
        padding-top: 71px;
    } */
}
/*警備会社　ナビバーカラー*/
.navbar-expand-lg .navbar-collapse.security,
.navbar-expand-lg .navbar-collapse.security ul li{
    background-color:#2b3b56;
}
.navbar-expand-lg .navbar-collapse.security ul li:hover {
    background-color:#144399;
}
/*警備依頼会社　ナビバーカラー*/
.navbar-expand-lg .navbar-collapse.client,
.navbar-expand-lg .navbar-collapse.client ul li{
    background-color:#0e958a;
}
.navbar-expand-lg .navbar-collapse.client ul li:hover {
    background-color:#00b3a4;
}
.navbar-light .navbar-nav .nav-link {
    padding:15px;
    text-align: center;
        color:#DDDDDD;
}
.navbar-light .navbar-nav .nav-link:hover {
    color:#FFFFFF;
}
#navbarSupportedContent.security > ul > li.nav-item.active > a,
#navbarSupportedContent.client > ul > li.nav-item.active > a{
    color:#FFFFFF;
}#navbarSupportedContent.security > ul > li.nav-item.active > a {
    background-color: #144399;
}
#navbarSupportedContent.client > ul > li.nav-item.active > a{
    background-color: #00b3a4;
}
#navbarSupportedContent.security > ul > li.nav-item > a,
#navbarSupportedContent.client > ul > li.nav-item > a {
    color:#eeeeee;
}
#navbarSupportedContent.security > ul > li.nav-item > a:hover {
    color:#FFFFFF;
    background-color: #144399;
}
#navbarSupportedContent.client > ul > li.nav-item > a:hover {
    color:#FFFFFF;
    background-color: #00b3a4;
}
.navbar-light .navbar-nav .active > .nav-link {
	color:#ffffff;
	font-weight:bold;
}
.navbar-toggler {
    float:right;
	border:none;
    padding: 0.75rem;
    font-size: 1.5rem;
}
.dropdown-menu {
    position: absolute;
    top: 100%;
    right: -26px;
    z-index: 1000;
    display: none;
    min-width: 3rem;
    padding: 0.5rem 0;
    margin: 00;
    font-size: 1rem;
    color: #000000;
    text-align: right;
    list-style: none;
    background-color: transparent;
    background-clip: padding-box;
    border-radius: 0;
    border:none;
    transition: all .4s ease 0s,background .6s ease .2s;
}
.dropdown-item {
    border-radius: 0 0 0 4px;
    display: inline;
    background-color: #aaaaaa;
    padding: 18px 20px;
    line-height: 2.4em;
}
.dropdown-item:hover {
    background-color: #cccccc;
    color:#2b3b56; 
}
a.sign-out {
   color:#000000; 
}
a.sign-out:hover {
   color:#000000;
   text-decoration: none;
}
@media only screen and (max-width: 991px) {
    .brand-row {
        display: inline-flex;
    }
    .mr-auto {
	    margin-right:0 !important;
	    width:100%;
    }
    .navbar-light .navbar-nav .nav-link{
        border:solid 1px rgba(255,255,255,0.2)
    }
}
/*フッター*/
#socket {
    background-color: #2b3b56;
    color: #dddddd;
    font-size: 11px;
    line-height: 1.5em;
    margin-top: -1px;
    padding: 15px 0;
    z-index: 1;
}
#socket ul {
    display: flex;
    flex-direction: row;
    margin-bottom:0;
    justify-content: center;
    padding-left: 0;
}
#socket ul li {
    float: left;
    display: block;
    list-style:none;
    padding: 0 10px;
    border-left-style: solid;
    border-left-width: 1px;
}
#socket ul li:first-child {
    border-left:none;
}
#socket a {
    color: #dddddd;
}
#socket a:hover {
        color: #ffffff;
}
.footer-link-menu {
    float:left;
}
.copyright {
    float:right;
    text-align: right;
}
@media only screen and (max-width: 991px){
    .footer-link-menu,
    .copyright {
        float: none;
    }
    .copyright {
        text-align: center;
        padding:5px 0;
    }
}
@media only screen and (max-width: 767px){
    .footer-link-menu {
        font-size:12px;
    }
    #socket ul {
	    flex-direction :column !important;
    }
    #socket ul li {
        border-left: none;
        padding: 6px 0;
        text-align: center;
        float: none;
    }
    .copyright {
        letter-spacing: -0.01em;
    }
}
/*to Top*/
/* #comprehensive_top > nav.navbar.top-link > a.navbar-brand {
	display:block;
    padding: 0 1rem 0.5rem 1rem;
    font-size:16px;
    font-weight:bold;
}
#comprehensive_top > nav.navbar.top-link {
    background-color:#617586;
}
#comprehensive_top > nav.navbar.top-link:hover {
    background-color:#7790a6;
} */
/*ボタン設定*/
.btn-contact,
.btn-detail{
	background-color: #ff5a75;
}
.btn-contact:hover,
.btn-detail:hover{
	background-color: #ff738a;
}
.btn-detail{
    font-size: 15px;
    font-weight: bold;
    padding: 0.375rem 1.5rem;
}
.btn-login-security {
	background-color: #144399;
    font-size: 18px;
}
.btn-login-security:hover {
	background-color: #2963cc;
}
.btn-login-client {
	background-color: #00b3a4;
    font-size: 18px;
}
.btn-login-client:hover {
	background-color: #00c7b6;
}
.btn-register {
	background-color: #dadada;
    font-size: 18px;
}
.btn-register:hover {
	background-color: #e6e6e6;
}
.btn-see-more {
	background-color: #7692cc;
    font-size:16px;
}
.btn-see-more:hover {
	background-color: #acc0e6;
}
/*メインビジュアル*/
h1#main-visual-message {
    color:#fff;
    font-weight:bold;
    line-height: 1.6em;
    margin-bottom: 1em;
}
@media only screen and (min-width: 1200px){
    #mainvisual {
        height:600px;
        padding: 6rem 8rem;
        padding-top: 0 !important;
    }
    img#mv_ribbon {
        height: auto;
        margin: 15px 0px 25px !important;
        width: 450px;
    }
    
}
@media only screen and (max-width: 1199px){
    #mainvisual {
        height:540px;
        padding: 5rem;
        padding-top: 0 !important;
    }
    img#mv_ribbon {
        height: auto;
        margin: 15px 0px 25px !important;
        width: 440px;
    }  
    a.btn.btn-contact.btn-lg {
	    font-size:24px;
    }
}
@media (min-width: 992px) {
    h1#main-visual-message{
       font-size: 3rem;
       margin-top: -0.5em;
     }
    a.btn.btn-contact.btn-lg {
	    font-size:22px;
    }
}
@media (max-width: 991px) {
    #mainvisual {
        height:450px;
        padding: 3rem;
        padding-top: 0 !important;
    }
    img#mv_ribbon {
        height: auto;
        margin: 15px 0px 5px !important;
        width: 370px;
    }
    h1#main-visual-message{
       font-size: 2.5rem;
     }
    a.btn.btn-contact.btn-lg {
        font-size: 20px;
    }
}
@media (max-width: 767px) {
    #mainvisual {
        height:320px;
        padding: 2rem 1rem;
        padding-top: 0 !important;
    }
    img#mv_ribbon {
        height: auto;
        margin: 15px 0px 5px !important;
        width: 295px;
    }
    h1#main-visual-message {
       font-size: 2.0rem;
     }
    a.btn.btn-contact.btn-lg {
        font-size: 18px;
    }
}
@media (max-width: 575px) {
    #mainvisual {
        height:240px;
        padding: 1rem 0.5rem;
        padding-top: 0 !important;
    }
    img#mv_ribbon {
        height: auto;
        margin: 15px 0px 10px !important;
        width: 220px;
    }
    h1#main-visual-message {
       font-size: 1.5rem;
     }
    a.btn.btn-contact.btn-lg {
        font-size: 16px;
    }
}
@media (max-width: 399px) {
    #mainvisual {
        height:180px;
        padding: 1rem 0.5rem;
        padding-top: 0 !important;
    }
    img#mv_ribbon {
        height: auto;
        margin: 15px 0px 10px !important;
    }
    h1#main-visual-message {
       font-size: 1.2rem;
     }
    a.btn.btn-contact.btn-lg {
        font-size: 14px;
    }
}
/*ログインフォームセクション*/
h3.form-title {
	color: #fff; padding: 15px 0;
    font-size: 20px;
	text-align: center; 
	margin-bottom: 0; 
	border-radius: 4px 4px 0 0;
}
h3.form-title.security {
	background: #144399;
}
h3.form-title.client {
	background: #0e958a; 
}
a.fgt-pwd {
	display:inline-block;
    font-size: 14px;
	margin:0;
	text-decoration:underline;
}
a.fgt-pwd.security {
    color: #144399;
}
a.fgt-pwd.security:hover {
    color: #2963cc;
    text-decoration:none;
}
a.fgt-pwd.client {
    color: #00b3a4;
}
a.fgt-pwd.client:hover {
    color: #00c7b6;
    text-decoration:none;
}
input.form-control {
	background-color:#f8f8f8;
}
.custom-control.custom-checkbox {
	font-size:0.92em;
	margin-bottom:5px;
}
@media (max-width: 479px) {
    .px-xs-0 {
        padding-left:0;
        padding-right: 0;
    }
}
/*AIK orderとは*/
#about-wrapper {
	display:flex;
	justify-content:center;
}
.about-logo {
	margin-right:50px;
}
h3.about-title {
    color:#ffffff;
	font-size: 35px; 
    font-weight:Bold;
	margin-top: 0; 
	padding-top: 50px;
}
h3.about-title .about-title.jp {
	font-size:0.6em;
}
p.about-lead {
    color:#ffffff;
	font-size:18px;
	line-height:2em;
}
@media only screen and (min-width: 768px) and (max-width: 1199px) {
    p.about-lead {
	    font-size:17px;
    }
}
@media only screen and (max-width: 767px) {
    #about-wrapper {
	    flex-direction:column;
	}
	.about-logo {
	    margin-right:0;
	    padding:0 20%;
    }
	h3.about-title {
        font-size: 30px;
        padding-top: 20px;
    }
	p.about-lead{
		font-size:16px;
		line-height:1.75em;
	}
    p.about-lead br {
	    display:none;
    }
}
@media only screen and (min-width: 576px) and (max-width: 639px) {
    h3.about-title {
        font-size: 26px;
    }
    p.about-lead {
        font-size: 15px;
    }
}
@media only screen and (max-width: 575px) {
	h3.about-title {
        text-align: center;
    }
    #about-logo.img-fluid {
    max-width: 30%;
    }
}
@media only screen and (max-width: 480px) {
     #about-logo.img-fluid {
        max-width: 50%;
    }   h3.about-title {
        font-size: 24px;
        padding-top: 10px;
    }
    p.about-lead{
		font-size:15px;
	}
}
/*ご契約のながれ*/
.nav-tabs .nav-link {
    border: solid 1px #ccc;
    border-bottom: none;
	color:#889095;
    padding: 12px 0 14px;
}
.nav-tabs .nav-link.active {
	color:#ffffff;
}
.nav-tabs .nav-link.active#tab-menu01 {
	background-color:#2b3b56;
}
.nav-tabs .nav-link.active#tab-menu02 {
	background-color:#0e958a;
}
.flow {
	display: flex;
}
.flow-step {
	flex-basis:18%;
}
.flow-item {
	background-color: #ffffff;
	border-radius: 2px;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	align-items: center;
	padding: 10px;
}
.flow-caret {
	align-items: center;
	display: flex;
	padding: 0 4px;
}
.flow-item img,
.flow-item h4 {
	margin:10px 0;
}
.flow-item h4 {
	display: flex;
    font-size:18px;
    font-weight:bold;
    line-height: 1.1em;
	height: 44px;
	margin:5px 0 5px 0;
    align-items: center;
}
p.flow-description {
    font-size: 14px;
    line-height: 1.5em;
	margin:0;
}
@media only screen and (min-width: 910px) {
	.flow-item {
	    height:230px;
}
}
@media only screen and (min-width: 768px) and (max-width: 1189px) {
     p.flow-description br {
		display:none;
}
}
@media only screen and (min-width: 768px) and (max-width: 909px) {
    #comprehensive_top > section:nth-child(6) > div.container{
	    max-width:100%;
    }
    .flow-item {
	    height:235px;
    }
    .flow-item h4  {
			font-size:16px;
	}
    .flow-item h4 br {
		display:none;
	}
}
@media only screen and (max-width: 767px) {
	.responsive .tabcontainer {
	    border-radius:4px;
	}
	div.flow {
      flex-direction: column;
	}
	.flow-item {
        flex-direction: row;	
		justify-content:center;
	}
	.flow-item h4 {
	    display: inline-block;
        height:auto;
        margin: 0 10px 0 20px;
        text-align: left !important;
        width: 110px;
    }
    p.flow-description{
        display: inline-block;
        width: 45%;
	}
     p.flow-description br {
		display:none;
    }
	.flow-caret {
		height:36px;
        margin: 0 auto;
	}
	.flow-caret img {
	    transform: rotate(90deg);
    }
}
@media (max-width: 767px) {
    .container, .container-sm, .container-md {
        max-width: 100%;
    }
}
@media (max-width: 575px){
    .container, .container-sm {
        max-width: 100%;
    }
}
@media only screen and (max-width: 480px) {
	.flow-item {
        flex-direction: column;
	}
	.flow-item h4 {
		margin:5px 0 0 0;
	    text-align:center !important;
	}
	p.flow-description{
        display: inline-block;
		padding:10px;
        width: auto;
	}
}
@media only screen and (max-width: 400px) {
    .nav-tabs .nav-link {
        font-size:12px;
    }
}
/*ご契約のながれ　STEP */
h3 .step-num {
	font-size:1.4em;
}
h3.step05 {
        font-size: 20px;
    font-weight:bold;
}
h3.flow-prosess {
	border-radius:2px 0 0 2px;
	display: inline-block;
    font-size: 20px;
    font-weight:bold;
	position: relative;
	top: 0;
	right: 0;
	margin: 0 0 10px;
	padding: 10px 0 ;
	z-index: 2;
	width: 100%;
	text-align: center;
	color: #ffffff;
    line-height: 1.1em;
}
h3.flow-prosess:after {
	content: "";
	position: absolute;
	left: 100%;
	top: 0;
	height: 0;
	width: 0;
	border-bottom: 22px solid transparent;
	border-top: 22px solid transparent;
}
.flow-step.security h3.flow-prosess.step01 {
	background: #b7ccd9;
}
.flow-step.security h3.flow-prosess.step01:after {
	border-left: 16px solid #b7ccd9;
}
.flow-step.security h3.flow-prosess.step02 {
	background: #98bdd9;
}
.flow-step.security h3.flow-prosess.step02:after {
	border-left: 16px solid #98bdd9;
}
.flow-step.security h3.flow-prosess.step03 {
	background: #7ba4cc;
}
.flow-step.security h3.flow-prosess.step03:after {
	border-left: 16px solid #7ba4cc;
}
.flow-step.security h3.flow-prosess.step04 {
	background: #5682bf;
}
.flow-step.security h3.flow-prosess.step04:after {
	border-left: 16px solid #5682bf;
}
.flow-step.security h3.step05 {
	border-radius:4px;
	display: inline-block;
	background: #3e67b3;
	margin: 0 0 10px;
	padding: 10px 0 ;
    width: 100%;
	text-align: center;
	color: #ffffff;
    line-height: 1.1em;
}
.flow-step.client h3.flow-prosess.step01 {
	background: #c3d9d2;
}
.flow-step.client h3.flow-prosess.step01:after {
	border-left: 16px solid #c3d9d2;
}
.flow-step.client h3.flow-prosess.step02 {
	background: #98d9c7;
}
.flow-step.client h3.flow-prosess.step02:after {
	border-left: 16px solid #98d9c7;
}
.flow-step.client h3.flow-prosess.step03 {
	background: #67ccb6;
}
.flow-step.client h3.flow-prosess.step03:after {
	border-left: 16px solid #67ccb6;
}
.flow-step.client h3.flow-prosess.step04 {
	background: #30bfa9;
}
.flow-step.client h3.flow-prosess.step04:after {
	border-left: 16px solid #30bfa9;
}
.flow-step.client h3.step05 {
	border-radius:4px;
	display: inline-block;
	background: #00b3a0;
	margin: 0 0 10px;
	padding: 10px 0 ;
    width: 100%;
	text-align: center;
	color: #ffffff;
    line-height: 1.1em;
}
@media only screen and (max-width: 767px) {
	h3.flow-prosess,
    .flow-step.security h3.step05,
	.flow-step.client h3.step05 {
		margin:0;
		border-radius:4px 4px 0 0;
	}
	h3.flow-prosess:after {
		display:none;
	}
}
/*会員登録CTA*/
.cta {
	background-color:#eeeeee; 
	border-radius:4px; 
	padding:20px; 
	display:flex;
	justify-content:center;
	max-width: 800px !important;
	clear: both !important;
	margin: 30px auto 0 auto !important;
	float:none !important;
}
.cta-wrapper {
	display:flex;
	align-items:center;
	justify-content:center;
}
.cta-bubble {
    display: flex;
	border-radius:4px;
	float:left;
	background-color:#ffffff;
	font-size:15px;
	padding:0 20px;
	margin-right:20px;
        align-items: center;
}
    .cta-bubble p{
        margin-bottom: 0;
    }
.cta-wrapper span.induction {
	font-size: 15px;
	font-weight: bold;
	display:inline-block;
	width:180px;
	margin-left:10px;
}
a.btn-register {
	border:none;
	border-radius:4px;
	color:#ffffff;
	font-size:15px;
	font-weight:bold;
	margin-left:15px;
	padding:6px 10px;
}
a.btn-register:hover {
		color:#ffffff;
}
a.btn-register.security {
	background-color:#144399;
}
a.btn-register.security:hover {
	background-color:#2963cc;
}
a.btn-register.client {
	background-color:#0e958a;
}
a.btn-register.client:hover {
	background-color:#00c7b6;
}
.cta-wrapper.security {
	margin-bottom:10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .cta {
	    padding:20px 25px; 
	    justify-content:normal;
	    max-width: 690px !important;
    }
}
@media only screen and (max-width: 767px) {
    .cta-bubble {
	    float:none;
        text-align:center;
	    width:100%;
		margin-bottom:15px;
    	justify-content: center;
	    margin-right: 0 !important;
	    padding: 20px;
	}
    .cta-bubble p br{
	    display:none;
	}
}
@media only screen and (max-width: 480px) {
	.cta-wrapper {
	    flex-direction:column;
    }
    .cta-bubble {
        font-size:16px;
        padding:15px;
    }
    .cta-security{
        justify-content: center;
        margin-bottom: 10px;
    }
    .cta-security{
        margin-bottom: 20px;
    }
	.cta-wrapper span.induction {
	    width:100%;
		text-align:center;
		padding:5px 0;
		margin:0;
	}
	img.cta-chevron {
		display:none;
	}
	a.btn-register {
	    margin:0 0 10px 0;
	}
    .btn-register.security {
		margin-bottom:0;
	}
}
/*警備カテゴリー*/
.category-img {
    float: left;
    margin: -4px 20px 0 0;
    width: 50%;
    height: auto;
}
.category-img img {
	border-radius:4px;
}
h4.category-title {
	color:#00ccbb !important;
    font-size: 18px;
    font-weight: bold;
}
p.category-description {
    font-size:14px;
}
@media only screen and (max-width: 767px) {
    .category-img {
        float: left;
        margin: -4px 20px 0 0;
        width: 40%;
    }
}
@media only screen and (max-width: 480px) {
    .category-img {
        float: none;
        margin: 0;
        width: 100%;
    }
	.category-img img {
	    margin-bottom:20px;
	}
}
/*ニュース*/
table#news td {
	background-color:#ffffff;
	border-bottom: dotted 1px #cccccc;
    font-size:13px;
    width:100%;
}
table#news td:first-child {
	border-left:none;
	border-right:none;
	color:#000000 !important;
	width:100px;
}
table#news td:nth-child(2) {
	border-right:none;
	color:#333333 !important;
	text-align:left;
}
table#news tr:last-child td {
	border-bottom-style:none;
}
span.new {
	border:solid 2px #ff5a75;
	border-radius:4px;
	color:#ff5a75;
	font-weight:bold;
	padding:1px 6px;
}
@media only screen and (max-width: 767px) {
    table#news  {
        border-left:none;
	    border-right:none;
        border-top: soid 1px #dee2e6;
	}
	table#news td:first-child {
		border-bottom:none;
		width:100px;
		text-align:left;
		padding-bottom:0
	}
			table#news tr:last-child td{
		border-bottom: none;
	}
}
@media only screen and (max-width:479px) {
    table#news {
        border-top:1px solid #dee2e6;
    }
    table#news td {
        display:block;
    }
    table#news td:first-child {
        border-bottom: none;
        width: 100%;
        text-align: left;
        padding-bottom: 5px;
        border-top: none;
    }
    	table#news td:nth-child(2) {
	    border-top:none;
	    padding-top:0;
	}
}
/*警備案件紹介*/
table.security-company-data td {
	background-color:#ffffff;
	color:#333333 !important;
    font-size: 14px;
	padding:6px 12px !important;
    vertical-align:middle;
}
table.security-company-data td:first-child {
	background-color:#f8f8f8;
	border-left:none;
	font-weight:bold;
	width:100px;
}
table.security-company-data td:nth-child(2)  {
	border-right:none;
    text-align:left !important;
}
.btn-company-detail a .avia_iconbox_title{
	font-weight:bold;
}
@media only screen and (max-width: 767px) {
    table.security-company-data {
	    border:solid 1px #dee2e6;
    }
    table.security-company-data td {
	    display:block !important;
	}
	table.security-company-data td:first-child {
        width: 100%;
        background-color: #eeeeee;
	    border-bottom:none;
    }
	table.security-company-data td:nth-child(2) {
		text-align:center !important;
        width:100%;
	}
}


/* 納品後修正分 */
.btn-register2 {
	background-color: #dadada;
    font-size: 18px;
}
.btn-register2:hover {
	background-color: #e6e6e6;
}
.comprehensive_top_title {
    font-size: 2rem;
}

.nav-tabs .nav-link:hover {
    background-color: #eeeeee;
}
#comprehensive_top {
    padding-top: 76px;
}
.comprehensive_top_icon-area {
    margin-top:6px;
}
/*to Top*/
#comprehensive_top > #page__top > .comprehensive_top_page__top-item {
    display: block;
    padding: 0 1rem 0.5rem 1rem;
    font-size: 16px;
    font-weight: bold;
}
#comprehensive_top > #page__top {
    background-color:#617586;
    border-bottom: none;
}
#comprehensive_top > #page__top:hover {
    background-color:#7790a6;
    cursor: pointer;
}
@media only screen and (max-width: 767px) {
    .comprehensive_top_title {
	    font-size:1.6rem;
    }
}
@media only screen and (max-width: 479px) {
    #comprehensive_top {
        padding-top: 71px;
    }
}
@media only screen and (min-width: 480px) {
    #comprehensive_top > nav > div > div.comprehensive_top_icon-area > p > a {
	    padding: 0.5rem;
	    position: relative;
	    top: 1px;
    }
}



@media only screen and (min-width: 480px) {
    .common-div{
        display: inline-flex;
    }
    .navbar-tel{
        padding-right: 50px;
    }
}


@media (min-width:320px) and (max-width:480px){
    .common-div{
        display: inline-flex;
    }
  .navbar-tel1{
    padding-top: 18px;
    width: 77px;
    margin-left: -8px;

  }
 }

@media only screen and (max-width: 320px) {
    .navbar-logo1{
    width:88px !important;
    }
    .common-div {
        display: inline-flex;
    }
     .msg-box{
     width:79px
  }
  .navbar-tel1{
    padding-top: 13px;
    width: 77px;
    margin-left: -8px;

  }
}