.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* elipsis 3 line start */
.show_3_line_max {
  height:60px;
  line-height:20px;
  overflow: hidden;
  /* text-overflow: ellipsis; */
  display: -webkit-box!important;
  /* display: block!important; */
  /* -webkit-line-clamp: 3; */ /* number of lines to show */
  -webkit-box-orient: vertical;
}
table.security-company-data td.show_3_line_max {
	padding:0px 12px !important;
}
/* elipsis 3 line end */
.allow_break {
  padding: 1rem;
  word-break: break-word;
}
.prevent_break {
  padding: 1rem;
  word-break: keep-all;
}